section.homepage-section {
  margin: 0 20px 30px 45px;
}

section.homepage-section.desktop {
  margin: 36px 52px 30px 90px;
}

section.homepage-section.desktop h1 {
  color: #02222B;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 20px;
}

section.homepage-section.campus-view.desktop h1 {
  margin: 0;
}

.single-building section.homepage-section {
  margin: 0 0 30px;
}

/*** NOTIFICATIONS ***/
.homepage-notifications {
  padding: 0 32px 0;
}

.homepage-notifications .notification {
  background: #FCFCFD;
  border: 1px solid #D0D5DD;
  border-radius: 12px;
  color: #475467;
  padding: 16px;
}

.homepage-notifications img {
  float: left;
  margin: 2px 0 0 1px;
}
.homepage-notifications  .notification-summary {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0 32px;
}
.homepage-notifications .notifcation-close {
  float: right;
  margin: 5px 5px 0 0;
}
.homepage-notifications .notification-title {
  color: #324E55;
  font-weight: 600;
}
.homepage-notifications .notification-footer {
  font-weight: 600;
  margin:12px 0 0;
}
.homepage-notifications .notification-footer a {
  color: #D55D06;
  margin: 0 0 0 15px;
}

/*** WIDGETS ***/
.dashboard-widgets {
  display: flex;
  padding: 0 32px 0;
}
.dashboard-widgets > .widget:first-child{
  margin:0 24px 0 0;
}

.widget {
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  display: flex;
  flex-direction: column;
}

.widget-header{
  border-bottom: #EAECF0 1px solid;
  padding: 20px 24px 20px 24px;
}

.widget-options {
  float: right;
  margin: 0 8px 0 0;
}

.widget-content {
  flex: 1;
  padding: 24px;
}

.widget-footer {
  border-top: #EAECF0 1px solid;
  box-sizing: border-box;
  height: 72px;
  max-height: 72px;
  overflow: hidden;
  padding: 16px 24px;
}

.widget-header-title {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.widget-header-with-icon .widget-header-title {
  margin: 0 0 0 72px
}

.widget-footer {
  border-top: #EAECF0 1px solid;
  padding: 16px 24px;
}

.widgets-peak-load-management {
  flex: 1;
}

.widget-icon {
  float: left;
}


/*** .widgets-peak-load-management  ***/
.widgets-peak-load-management .widget-header-title > div {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.widget-footer a.widget-footer-link {
  display:inline-block;
  font-weight: 600;
  padding: 10px 0;
}

.widget-footer a.btn {
  float: right;
  margin: 0;
}

.widget-notifications .notification {
  border: 1px solid #F2F4F7;
  border-radius: 12px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  margin: 0 0 4px;
  padding: 16px;
}
.widget-notifications .notification-title {
  font-weight: 600;
  margin: 0 0 0 56px;
}
.widget-notifications .notification-icon {
  float: left;
}
.widget-notifications .notification-footer {
  font-weight: 600;
  margin: 0 0 0 56px;
  padding: 16px 0 0 0;
}
.widget-notifications .notification-footer a.view-link {
  color: #D55D06;
  margin: 0 0 0 12px;
}


/*** DASHBOARD HEADER STYLES ***/
.dashboard-cards-summary .card-summary-filters {
  display: flex;
  justify-content: space-between;
}

.dashboard-cards-summary .card-summary-filters input[type=search] {
  background: url('../assets/icon_search.svg') no-repeat 17.5px center;
  background-size: 15px 15px;
  font-size: 14px;
  padding: 10px 10px 10px 42px;
}

/* clears the search 'x' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { 
  display: none;
}

.homepage-header {
  align-items: center;
  display: flex;
  height: 57px;
  justify-content: space-between;
}


/*** CAMPUS GRAPH STYLES ***/
.homepage-campus-detail .campus-container .campus-card {
  cursor: pointer;
  transition: ease-in all 150ms;
  &:hover {
    transform: scale(1.05);
    transition: ease-in all 150ms;
  }
}

.hompage-campus-detail.graph {
  background: white;
  border-radius: 12px;
  color: #324E55;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  justify-content: space-between;
  padding: 35px 0 60px;
}

.hompage-campus-detail.graph .campus-table-detail {
  width: 100%;
}

/* .hompage-campus-detail.graph .campus-table-detail h4 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
}

.hompage-campus-detail.graph .campus-table-detail hr {
  background: #EAECF0;
  border: none;
  height: 2px;
  margin: 0 0 24px;
} */

.hompage-campus-detail.graph .campus-table-detail .temp-data {
  column-gap: 28px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 28px;
}

.hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item {
    align-items: flex-start;
    box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    padding: 15px 25px;
    width: 100%;
}

.hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item .temp-label,
.hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item .temp-number {
  font-size: 1.6vw;
}

.hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item .temp-label {
  font-weight: 400;
}

.hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item .temp-number {
  font-weight: 600;
}

.hompage-campus-detail.graph .all-graphs {
  align-items: center;
  box-shadow: rgba(194, 208, 209, .5) 0 0 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 26px 26px 16px;
  width: calc(100% - 52px);
}

.hompage-campus-detail.graph .all-graphs > div {
  width: 35vw;
}

.hompage-campus-detail.graph .campus-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.hompage-campus-detail.graph .campus-header h3 {
  margin: 0 0 24px;
}

.hompage-campus-detail.graph #energyInsightsChart {
  background: white;
  padding-top: 16px;
}


/*** BUILDING SUMMARY STYLES ***/
.dashboard-cards-summary.building-summary .homepage-building-rows {
  display: flex;
  flex-direction: column;
}

.dashboard-cards-summary.building-summary .building-row {
  border-bottom: 3px solid #EAECF0;
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;
  width: 100%;
}

.dashboard-cards-summary.building-summary .building-row:last-child {
  border-bottom: none;
}



/*** ZONE VIEW STYLES ***/
.building-summary-conatiner, .meter-summary-container, .zone-summary-container {
  margin-bottom: 10px;
}



/*** ZONE FILTER STYLES ***/
.filter-container {
  margin: 0 0 24px;
  width: 100%;
}

.filter-container .filter {
  color: #324E55;
  font-weight: 600;
}

.filter-container .filter {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  width: 75px;
}

.filter-container .filter .filter-name-icon {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.filter-container .filter .filter-name-icon img {
  padding-right: 2px;
}

.filter-container .filter svg.chevron {
  transform: rotate(270deg);
  width: 16px;
}

.filter-container .filter.hide svg.chevron {
  transform: rotate(90deg);
  width: 16px;
}

.filter-container .filter svg.chevron path {
  stroke: #324E55;
  stroke-width: 3px;
}

.filter-container .filter-items {
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 180px));
  justify-items: stretch;
  margin-top: 12px;
  row-gap: 10px;
  white-space: nowrap;
}

.filter-container .filter-item {
  height: 20px;
  margin-bottom: 8px;
  /* width: 50%; */
}

.filter-container .container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: #324E55;
  cursor: pointer;
  display: block;
  font-size: 12px;
  line-height: 1.5;
  padding-left: 20px;
  position: relative;
  user-select: none;
}

.filter-container .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 4px;
}

.filter-container .container:hover input ~ .checkmark {
  background-color: #ccc;
}

.filter-container .container input:checked ~ .checkmark {
  background-color: #D1FADF;
  border: solid 1px #12B76A;
  border-radius: 4px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-container .container input:checked ~ .checkmark:after {
  display: block;
}

.filter-container .container .checkmark:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  height: 8px;
  border: solid #12B76A;
  border-width: 0 2px 2px 0;
  left: 5px;
  top: 1px;
  transform: rotate(45deg);
  width: 4px;
}



/*** ZONE SUMMARY STYLES ***/
.zone-summary-container .card-summary-header {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
}

section.homepage-section .zone-summary-container .card-summary-header h1 {
  color: #324E55;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  margin: 20px 0 20px;
}

section.homepage-section .zone-summary-container .card-summary-header.desktop h1 {
  font-size: 16px;
}

.zone-summary-container .card-summary-header h1 span {
  background: #F9FAFB;
  border-radius: 16px;
  color: #324E55;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  mix-blend-mode: multiply;
  padding: 2px 8px;
  text-align: center;
  vertical-align: middle;
}

section.homepage-section .zone-cards-container.desktop {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}


/*** ZONE SUMMARY CARDS ***/
.dashboard-cards-summary .summary-cards {
  display: flex;
  flex-direction: column;
  padding: 0 24px 14px;
}

.homepage .zone-details {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  transition: ease-in-out all 250ms;
  width: 20vw;
}

.homepage .zone-details span {
  font-weight: 600;
  padding-right: 5px;
  transition: ease-in-out all 250ms;
}

.homepage .zone-details .hide {
  transform: rotate(90deg);
  transition: ease-in-out all 250ms;
}

.homepage .zone-details .show {
  transform: rotate(270deg);
  transition: ease-in-out all 250ms;
}

.homepage .zone-details svg path {
  stroke: #1D2939;
}


/*** ZONE SUMMARY STATUS ***/
.homepage .zone-status-container .zone-activity {
  align-items: flex-start;
  color: #1D2939;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
}

.homepage .zone-status-container .zone-activity .data-container {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  padding: 0 0 23px;
  width: 100%;
}

.homepage .zone-status-container .zone-activity .data-container span {
  font-size: 14px;
  font-weight: 600;
}

.homepage .zone-status-container .zone-activity .data-container > span div {
  align-items: flex-end;
  display: flex;
  font-size: 12px;
  font-weight: 500;
}

.homepage .zone-status-container .zone-activity .data-container > div img {
  padding-right: 5px;
}

.homepage .zone-status-container .zone-activity .data-container.control-mode > p,
.homepage .zone-status-container .zone-activity .data-container.control-mode .schedule-link p {
  font-size: 12px;
  margin: 0;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.homepage .zone-status-container .zone-activity .data-container.control-mode .schedule-link {
  border-bottom: 1px solid #1D2939;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: ease-in-out all 250ms;
  white-space: nowrap;
  &:hover {
    opacity: 0.5;
    transition: ease-in-out all 250ms;
  }
}

.homepage .zone-status-container .zone-activity .data-container.control-mode .schedule-link.mobile {
  pointer-events: none;
}

.homepage .zone-status-container .zone-activity .data-container.control-mode .schedule-link img {
  padding-left: 3px;
  padding-right: 0;
}

.homepage .zone-status-container .zone-settings-link {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  width: 6vw;
}

.homepage .zone-status-container .zone-status-right .zone-settings-link img {
  height: 20px;
  padding: 0;
  transition: ease-in all 150ms;
  width: 20px;
  &:hover {
    filter: brightness(0);
    transition: ease-in all 150ms;
  }
}

.homepage .zone-status-in-zone {
  padding: 0 0 7px;
}


/*** ZONE ERROR MESSAGE ***/
.homepage-zone-settings .zone-message {
  margin-bottom: 24px;
}


/*** ZONE SETTINGS ***/
.homepage-zone-settings .zone-card-container {
  display: flex;
  flex-direction: column;
}

.homepage-zone-settings .zone-card-container .summary-card {
  margin: 0;
}

/* ZONE COMPONENT ORDER MOBILE */
.homepage-zone-settings .zone-card-container .zone-card {
  order: 1;
  pointer-events: none;
}

.homepage-zone-settings .zone-card-container .zone-chart-container {
  border-radius: 8px;
  margin: 20px 0 30px;
  order: 2;
}

.homepage-zone-settings .zone-card-container .zone-status-container {
  margin-top: 20px;
  order: 3;
}

.homepage-zone-settings .zone-card-container .zone-settings-container {
  margin-top: 20px;
  order: 4;
}

/* ZONE COMPONENT ORDER DESKTOP */
.homepage-zone-settings .zone-card-container.desktop .zone-card {
  order: 1;
  padding-bottom: 24px;
  pointer-events: none;
  width: 100%;
}

.homepage-zone-settings .zone-card-container.desktop .zone-card .summary-card .general-view {
  height: 210px;
}

.homepage-zone-settings .zone-card-container.desktop .zone-chart-container {
  margin-top: 30px;
  order: 4;
  width: 100%;
}

.homepage-zone-settings .zone-card-container.desktop .zone-status-container {
  margin-top: 0;
  order: 2;
  width: 45%;
}

.homepage-zone-settings .zone-card-container.desktop .zone-status-container .zone-activity > div {
  padding: 0 0 25px;
}

.homepage-zone-settings .zone-card-container.desktop .zone-settings-container {
  margin-top: 0;
  order: 3;
  width: 45%;
}


/* ZONE SETTINGS STYLES */
section.homepage-section.desktop.homepage-zone-settings.desktop {
  margin: 20px 52px 30px 90px;
}

.homepage-zone-settings .zone-card-container.desktop {
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.homepage-zone-settings .zone-status-header,
.homepage-zone-settings .zone-settings-header {
  color: #324E55;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.homepage-zone-settings .zone-status-header hr,
.homepage-zone-settings .zone-settings-header hr {
  background: #EAECF0;
  border: none;
  height: 2px;
  margin: 18px 0;
}

.homepage-zone-settings .zone-settings-container .unmanaged {
  filter: opacity(0.25) grayscale(1);
  pointer-events: none;
}

.homepage-zone-settings .zone-settings-container.processing {
  filter: opacity(0.4);
  pointer-events: none;
}

.homepage-zone-settings .zone-settings-container .zone-status-details > div.zone-dropdown {
  align-items: center;
  color: #1D2939;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 0 10px;
}

.homepage-zone-settings .zone-settings-container .zone-status-details > div:last-child {
  padding: 0;
}

.homepage-zone-settings .zone-status-details select {
  padding: 4px 28px 4px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

.homepage-zone-settings .zone-modal-buttons {
  display: flex;
  flex-direction: column;
}

.homepage-zone-settings.desktop .zone-modal-buttons {
  display: flex;
  cursor: pointer;
}

.homepage-zone-settings.desktop .zone-modal-buttons > div {
  height: 18px;
  padding: 4px 0 0;
  width: 100%;
}

.homepage-zone-settings .zone-status-buttons {
  border: 2px solid #58DBAC;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0;
  padding: 5px 0;
  text-align: center;
  width: 100%;
}

.homepage-zone-settings.desktop .zone-status-buttons {
  margin: 0 0 8px;
  padding: 2px 0 0;
  width: 32%;
}

.homepage-zone-settings .zone-status-buttons.schedule-button,
.homepage-zone-settings .zone-status-buttons.override-button {
  background: #58DBAC;
  color: #02222B;
  transition: ease-in-out all 250ms;
  &:hover {
    background: #0EAA82;
    border-color: #0EAA82;
    transition: ease-in-out all 250ms;
  }
}

.homepage-zone-settings .zone-status-buttons.cancel-overrides-button {
  background: white;
  color: #02222B;
  transition: ease-in-out all 250ms;
  &:hover {
    background: #0EAA82;
    transition: ease-in-out all 250ms;
  }
}

.homepage-zone-settings .zone-status-buttons.cancel-overrides-button.disabled,
.homepage-zone-settings.desktop .zone-status-buttons.cancel-overrides-button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  filter: opacity(0.5);
}

.homepage-zone-settings .zone-status-value {
  color: #475467;
  flex: 1;
  font-size: 13px;
  font-weight: 500;
}

/*** ZONE CHARTS DESKTOP ***/
/* .zone-chart-container {
  background: #F2F4F7;
  height: 300px;
  padding: 12px;
} */

.zone-chart-container {
  background: #F2F4F7;
  height: 100%;
  padding: 0;
}

.zone-chart-container .active-chart-container {
  margin: 24px;
}

.zone-chart-container .active-chart-container .hidden {
  display: none;
  height: 0; 
}

.zone-chart-container .active-chart-container .performanceGraph {
  height: 600px;
}



/*** ZONE SETTINGS MODAL ***/
.modal-homepage-zone-settings .settings-row {
  display: flex;
  margin: 0 0 12px;
}

.modal-homepage-zone-settings .settings-row > * {
  align-items: center;
  display: flex;
  flex: 1;
}

.modal-homepage-zone-settings .settings-row .setting-input {
  justify-content: end;
}

.modal-homepage-zone-settings .settings-row label {
  color: #324E55;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.modal-homepage-zone-settings .temperature-adjustment {
  border-bottom: 2px solid #EAECF0;
  margin-bottom: 40px;
  padding: 20px;
  text-align:center;
}

.modal-homepage-zone-settings .temperature-adjustment .setpoint {
  color: #324E55;
  display: inline-block;
  font-size: 72px;
  font-weight: 600;
  letter-spacing: -1.44px;
  line-height: 90px;
  position:relative;
}

.modal-homepage-zone-settings .decrement-setpoint, .modal-homepage-zone-settings .increment-setpoint {
  border: 1px solid var(--gray-300, #D0D5DD);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 0;
  left: -64px;
  line-height: 1;
  padding: 12px;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
}

.modal-homepage-zone-settings .increment-setpoint {
  left: auto;
  right: -64px;
  text-align:center;
}

.modal-homepage-zone-settings .home-schedule-event-fields-container .frm-field-container,
.modal-homepage-zone-settings .home-schedule-event-fields-container .time-container {
  padding-bottom: 24px;
}

.modal-homepage-zone-settings label {
  color: #324E55;
  font-weight: 500;
}

.modal-homepage-zone-settings .home-schedule-event-fields-container .frm-field-container label > div {
  padding-top: 12px;
}

.modal-homepage-zone-settings .home-schedule-event-fields-container .time-container .start-time-container {
  padding-bottom: 24px;
}


/* DESKTOP */
.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .frm-field-container,
.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .frm-field-container input {
  width: 100%;
  z-index: 10;
}

.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .time-container,
.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .lowerUpperMode-container {
  height: 173px;
}

.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .time-container {
  margin-right: 24px;
  width: 35%;
}

.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .lowerUpperMode-container {
  width: 25%;
}

.modal-homepage-zone-settings.desktop .home-schedule-event-fields-container .lowerUpperMode-container .mode-container {
  padding: 0;
}

/* MEDIA QUERIES */
@media screen and (min-width: 1024px) {
  .hompage-campus-detail.graph .campus-table-detail {
    width: 100%;
  }

  .hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item .temp-label {
    font-size: 14px;
  }

  .hompage-campus-detail.graph .campus-table-detail .temp-data .temp-data-item .temp-number {
    font-size: 18px;
  }

  .hompage-campus-detail.graph .all-graphs > div {
    width: 50vw;
  }

  .homepage .zone-status-container .zone-activity .data-container.control-mode .schedule-link {
    max-width: 170px;
    width: unset;
  }

  .homepage-zone-settings .zone-card-container.desktop .zone-card {
    padding-bottom: unset;
    width: 33%;
  }

  .homepage-zone-settings .zone-card-container.desktop .zone-status-container {
    width: 30%;
  }

  .homepage-zone-settings .zone-card-container.desktop .zone-settings-container {
    width: 30%;
  }
}

@media screen and (min-width: 1400px) {
  section.homepage-section.desktop,
  .homepage-header,
  .dashboard-cards-summary.building-summary .homepage-building-rows {
    max-width: 75vw;
  }

  section.homepage-section.desktop {
    margin: 36px auto;
  }

  section.homepage-section.desktop.homepage-zone-settings.desktop {
    margin: 20px auto 90px;
  }

  .homepage-header {
    margin: 26px auto 0;
  }

  .dashboard-cards-summary.building-summary .homepage-building-rows {
    margin: 0 auto;
  }
}