@import-normalize;

html, body {
  background:#fff;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
}

a {
  text-decoration: none;
  color: #475467;
}

.btn {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #324E55;
  border: 1px solid #D0D5DD;
  display:inline-block;
  font-weight: 600;
  text-align:center;
  background: #fff;
  padding:10px 16px;
}

#root, .App, .logging-in-screen {
  display: flex;
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-flow: column;
  position: relative;
}


/* HIGHCHARTS CSS */
li.highcharts-menu-item {
  font-size: 13px !important;
  font-weight: 500;
}

.highcharts-data-table {
  height: 300px;
  overflow: auto;
  padding: 20px 0 0;
}

.highcharts-data-table table {
  width: 100%;
}

td.highcharts-number {
  text-align: center;
}

/* LIVE/HISTORY GRAPH CSS */
.chart-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 24px;
}

.chart-nav .tabs {
  display: flex;
}

.chart-nav .tabs .live,
.chart-nav .tabs .history {
  border-bottom: 4px solid #EAECF0;
  color: #1D2939;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.75;
  padding-bottom: 8px;
  text-align: center;
  transition: ease-in-out all 250ms;
  width: 120px;
}

.chart-nav .tabs .live.active,
.chart-nav .tabs .history.active {
  border-bottom: 4px solid #15B79E;
  opacity: 1;
  transition: ease-in-out all 250ms;
}

.chart-nav .refresh {
  background: #324E55;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 0;
  text-align: center;
  transition: ease-in all 150ms;
  width: 130px;
}

/* ELEXITY BANNER */
.elexity-banner {
  align-items: center;
  background: #EAECF0;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}

/* MAIN CONTNET */
.sidebar {
  height: calc(100vh - 52px);
  margin-top: 52px;
}

.main-content {
  overflow-x: hidden;
  width: 100%;
}

.center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.logging-in-screen {
  display: flex;
  justify-content: center;
  text-align: center;
}

@import 'forms.css';
@import 'modal.css';
@import 'header.css';
@import 'sidebar.css';
@import 'homepage.css';
@import 'buildingCards.css';
@import 'energy.css';
@import 'schedules.css';
@import 'events.css';
