* {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.page-header {
  display: flex;
  flex-direction: column;
  margin: 62px 20px 0 45px;
}

.page-header.desktop {
  margin: 72px 52px 0 90px;
}

.page-header hr {
  background: #EAECF0;
  border: none;
  clear: both;
  height: 2px;
  margin: 10px 0 8px;
}

.page-header.desktop hr {
  height: 5px;
  margin: 24px;
}

.page-header hr.hidden {
  display: none;
}

/* TOP */
.header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header-container .main-navigation {
  color: #324E55;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.15;
  width: 75vw;
}

.header-container .main-navigation.desktop {
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
}

.header-container .main-navigation.desktop .desktop-nav > button > a {
  align-items: center;
  display: flex;
  height: 53px;
  justify-content: center;
  width: 100%;
}

.header-container .main-navigation.desktop .desktop-subnav {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 50px 0 20px;
}

.header-container .main-navigation.desktop .desktop-subnav > div {
  align-items: center;
  border: none;
  display: flex;
  color: #324E55;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  padding: 0;
  transition: ease-in-out all 250ms;

  &:hover {
    opacity: 0.5;
    transition: ease-in-out all 250ms;
  }
}

.header-container .main-navigation.desktop .desktop-subnav > div img {
  padding: 3px 5px 0;
  transform: rotate(90deg);
}

.header-container .main-navigation .building-nav {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.header-container .main-navigation .building-nav span {
  text-overflow: ellipsis;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
}

.header-container .main-navigation .building-nav svg {
  margin-right: 4px;
  transform: rotate(180deg);
  width: 20px;
}

.header-container .main-navigation .building-nav svg path {
  stroke: #98A2B3;
  stroke-width: 2.5;
}

.page-header .page-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin:0 0 24px 0;
}