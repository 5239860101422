section.schedules {
  color: #324E55;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
}

.schedules.processing {
  filter: opacity(0.4);
  pointer-events: none;
}

.schedules .schedules-container {
  margin: 50px 52px 30px 90px;
}

.schedules .schedules-container .main-containers {
  margin-bottom: 30px;
}

.schedules .schedules-container h1 {
  color: #02222B;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 20px;
}

.schedules .schedules-container .schedule-header hr {
  background: #EAECF0;
  border: none;
  height: 2px;
}

.schedule-header .title-add-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.schedules .schedules-container .schedule-header .title-add-button h3{
  font-size: 16px;
  font-weight: 600;
}

.schedules .schedules-container .schedule-header .title-add-button .add-button {
  align-items: center;
  background: #58DBAC;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  transition: ease-in-out all 250ms;
  width: 24px;
  &:hover {
    background: #0EAA82;
    transition: ease-in-out all 250ms;
  }
}

.schedules .schedules-container .schedule-header .title-add-button .add-button img {
  filter: brightness(0);
}

/* SCHEDULE MAIN TABLE STYLES */
.schedules-table table {
  border: 3px #EAECF0 solid;
  border-collapse: separate;
  border-radius: 12px;
  border-spacing: 0;
  margin-top: 18px;
  overflow: hidden;
  width: 100%;
}

/* .single-entry td {
  border-bottom: 2px solid #D0D5DD;
} */

tr.multi-entry td div {
  padding: 5px 0 0;
}

.schedules-table table tr.single-entry:last-child td {
  border-bottom: none;
}

tr.title-row:nth-child(1n + 2) td {
  border-top: 2px solid #D0D5DD;
}

tr.cal-adjustment td {
  background: #F2F4F7;
}

.schedules-table table thead {
  background: #EAECF0;
}

.schedules-table table thead tr th:first-child {
  text-align: left;
  padding: 10px 0 10px 24px;
}

.schedules-table table thead tr th {
  font-size: 12px;
  font-weight: 600;
  padding: 10px 0;
}

.schedules-table table tbody tr {
  height: 60px;
}

.schedules-table table tbody tr td {
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  width: 22%;
}

.schedules-table table tbody tr td.mode-container div.mode {
  border-radius: 50px;
  font-weight: 600;
  margin: 0 auto;
  padding: 3px 0;
  width: 100px;
}

.schedules-table table tbody tr td:first-child {
  font-weight: 600;
  max-width: 155px;
  padding-left: 24px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.schedules-table table tbody tr td:nth-of-type(3),
.schedules-table table tbody tr td:nth-of-type(4),
.schedules-table table tbody tr td:nth-of-type(5) {
  width: 13%;
}

.schedules-table table tbody tr td:last-child {
  width: auto;
}

.schedules-table table tbody tr.multi-entry td.detail-mode > div,
.schedules-table table tbody tr.single-entry td.detail-mode > div {
  border-radius: 50px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 auto;
  padding: 2px;
  width: 90px;
  &.auto {
    background: #EAECF5;
    color: #4E5BA6;
  }
  &.heat-only {
    background: #FFE6D5;
    color: #E62E05;
  }
  &.cool-only {
    background: #D1E9FF;
    color: #2E90FA;
  }
  &.off {
    background: #F2F4F7;
    color: #98A2B3;
  }
}

.schedules-table table tbody tr td.edit-delete-container {
  align-items: center;
  display: flex;
  flex-flow: column;
  font-weight: 600;
  height: 40px;
  justify-content: space-around;
  text-decoration: underline;
}

.schedules-table table tbody tr td.edit-delete-container .delete {
 font-weight: 500;
}

.schedules-table table tbody tr td.edit-delete-container .delete,
.schedules-table table tbody tr td.edit-delete-container .duplicate,
.schedules-table table tbody tr td.edit-delete-container .edit {
  cursor: pointer;
  transition: ease-in-out all 250ms;
  &:hover {
    opacity: 0.5;
    transition: ease-in-out all 250ms;
  }
}

/* SCHEDULE ASSIGNMENT TABLE */
.schedule-assignment-table.processing {
  filter: opacity(0.4);
  pointer-events: none;
}

.schedule-assignment-table.schedules-table table {
  border-color: #324E55;
}

.schedule-assignment-table.schedules-table table thead {
  background: #324E55;
  color: white;
}

.schedule-assignment-table.schedules-table table tbody tr td {
  border-bottom: 1px solid #EAECF0;
}

.schedule-assignment-table.schedules-table table tbody tr td select {
  font-size: 12px;
}

.schedule-assignment-table.schedules-table table tbody tr td:first-child {
  width: 80%;
}

.schedule-assignment-table.schedules-table table tbody tr td:last-child {
  padding-right: 24px;
  text-align: right;
  width: 20%;
}


/*** HomepageModalScheduleEvent STYLES ***/
.frm-schedule-event {
  border-radius: 12px;
  border: 1px solid #EAECF0;
}

.frm-schedule-event-fields-container {
  padding: 24px;
}

.frm-schedule-event input[type=text] {
  margin: 3px 0 0;
  width: 100%;
}

.frm-schedule-event .frm-field-container {
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 24px 0;
}

.schedule-event-bounds-temperatures .temperature-box-container {
  margin: 0 0 24px 0;
}

/*** event edit form ***/
.frm-edit-event-container {
  flex: 1
}

.frm-edit-event-container .btn-container {
  border-top: #EAECF0 1px solid;
  padding: 16px 24px;
  text-align: right;
}

.frm-edit-event-container .btn-container input {
  margin:0 0 0 16px;
}


.schedules-chxlist {
  min-width: 231px;
  border-radius: 12px;
  border: 1px solid var(--gray-200, #EAECF0);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  overflow: hidden;
}
.schedules-chxlist-hdr {
  background: #F9FAFB;
  border-bottom: 1px solid #EAECF0;
  padding: 13px 0 13px 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray-600, #475467);
}

.schedules-chxlist label {
  display: block;
  border: 1px solid #EAECF0;
  padding: 8px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

}
.schedules-chxlist label input[type=checkbox] {
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  display: inline-block;
  margin:0 10px 0 0;
  vertical-align: middle;
}


/* MEDIA QUERIES */
@media screen and (max-width: 450px) {
  .frm-edit-event-container {
    padding: 20px 0 0;
  }

  .schedule-events-section {
    display: block;
    padding: 0 16px 10px 16px;
  }

  .event-calendar-container {
    width: auto;
    min-width: 275px;
    padding:0;
  }

  .schedules-chxlist {
    min-width: initial;
    width: 100%;
  }
  
  .frm-edit-event-container .btn-container {
    padding: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .schedules-table table tbody tr td.edit-delete-container {
    flex-flow: unset;
    min-width: 200px;
  }
}

@media screen and (min-width: 1400px) {
  .schedules .schedules-container {
    margin: 50px auto 90px;
    max-width: 75vw;
  }
}
