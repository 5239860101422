.summary-card {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
}

section.homepage-section .zone-cards-container.desktop .summary-card {
    margin-bottom: 0;
}
  
.summary-card .general-view {
    align-items: flex-start;
    color: #1D2939;
    display: flex;
    flex-flow: column;
    height: 200px;
    justify-content: space-between;
    padding: 14px;
}

section.homepage-section .zone-card-container.desktop .summary-card .general-view {
    height: 190px;
    padding: 24px 14px;
}

/*** ZONE CARD HEALTH ***/
.summary-card.good {
    background: #F6FEF9;
    border-left: 12px solid #12B76A;
}
  
.summary-card.warn,
.summary-card.offline {
    background: #FFFCF5;
    border-left: 12px solid #FA974F;
}
  
.summary-card.fault,
.summary-card.unhealthy {
    background: #FFFBFA;
    border-left: 12px solid #F04438;
}
  
.summary-card.override,
.summary-card.unmanaged {
    background: #F9FAFB;
    border-left: 12px solid #EAECF0;
}
  

/*** ZONE CARD HEADER ***/
.zone-status-card-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 10px;
    width: 100%;
}
  
.zone-status-card-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    max-height: 40px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    width: 95%;
}

.zone-cards-container.desktop .zone-status-card-name {
    max-width: 250px;
}
  
.zone-status-card-name img {
    margin: 0 0 0 8px;
    vertical-align: middle;
}

.zone-status-card-header .zone-status-card-schedule {
    font-size: 12px;
}

.zone-status-card-header .zone-status-card-schedule .schedule-override span {
    align-items: center;
    display: flex;
}

.zone-status-card-header .zone-status-card-schedule .schedule-override span p {
    margin: 5px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.zone-status-card-header .zone-status-card-schedule img {
    padding-left: 4px;
}


/*** ZONE CARD THERMOSTAT ***/
.zone-current-status {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%;
}

.zone-current-status.no-temp {
    justify-content: flex-end;
}

.zone-current-status .zone-thermostat-container {
    display: flex;
}

.zone-current-status .zone-thermostat-container .zone-status-current-temperature {
    color: #1D2939;
    font-size: 50px;
    font-weight: 600;
    line-height: 80px;
    margin-right: 0px;
    width: 80px;
}

.zone-current-status .zone-thermostat-container .zone-thermostat-indicator {
    width: 100%;
}

.zone-status-thermostat {
    height: 96px;
    margin-left: 10px;
    position: relative;
    width: 10px;
}
  
.zone-status-thermostat-indicator {
    background: #FFFFFF;
    border: 1px solid var(--gray-400, #98A2B3);
    border-radius: 8px;
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10));
    height: 8px;
    left: 0px;
    position: absolute;
    width: 8px;
}
  
.zone-status-thermostat:before {
    background: #EAECF0;
    border-radius: 4px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 8px;
}
  
.summary-card.good .zone-status-thermostat:before {
    background: #EAECF0;
}
  
.zone-status-thermostat-top, 
.zone-status-thermostat-bottom {
    color: #98A2B3;
    font-size: 14px;
    font-weight: 400;
}
  
.zone-status-thermostat-top.has-temperature, 
.zone-status-thermostat-bottom.has-temperature {
    font-size: 14px;
    font-weight: 600;
}
  
.zone-status-thermostat-top {
    position: absolute;
    right: -20px;
    top: 0;
}
  
.zone-status-thermostat-bottom {
    bottom: 0;
    position: absolute;
    right: -20px;
}


/*** ZONE CARD STATS ***/
.zone-current-status .zone-stats {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    height: 58px;
    justify-content: space-between;
}

.zone-current-status .zone-stats > div {
    align-items: center;
    display: flex;
}

.zone-current-status .zone-stats .activity-state,
.zone-current-status .zone-stats .control-mode,
.zone-current-status .zone-stats .keypad-status {
    align-items: center;
    display: flex;
}

.zone-current-status .zone-stats > div img {
    padding-right: 3px;
}

.zone-current-status .zone-stats .control-mode {
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 6px;
    text-align: center;
}

.zone-current-status .zone-stats .control-mode.offline {
    background: #FEDF89;
    color: #324E55;
}

.zone-current-status .zone-stats .control-mode.unmanaged {
    background: #EAECF0;
    color: #324E55;
}

.zone-current-status .zone-stats .control-mode.elexity-optimized {
    background: #D1FADF;
    color: #05603A;
}

.zone-current-status .zone-stats .control-mode.problem {
    background: #FECDCA;
    color: #912018;
}

@media screen and (min-width: 1024px) {
    .zone-status-card-name {
        font-size: 20px;
        line-height: 28px;
        max-height: 56px;
    }
}