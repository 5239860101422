.sidebar {
  background: #fff;
  position: absolute;
  text-align: left;
  z-index: 5;
}

.sidebar.hide {
  background: #667085;
  min-width: unset;
  width: 25px;
}

.sidebar.hide .sidebar-main-container {
  display: none;
  overflow: hidden;
}

.sidebar.hide .show-navbar {
  left: 10px;
  transition: ease-in-out transform 250ms;
}

.sidebar .show-navbar {
  align-items: center;
  background: #667085;
  border: 2px solid white;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: center;
  right: -16px;
  position: absolute;
  top: 36px;
  transition: ease-in-out transform 250ms;
  width: 26px;
}

.sidebar .show-navbar svg {
  height: 20px;
  width: 20px;
}

.sidebar .show-navbar.show svg {
  transform: rotate(180deg);
  transition: ease-in-out transform 250ms;
}

.sidebar .show-navbar svg path {
  stroke: white;
  stroke-width: 3px;
}

.sidebar-overlay.show {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background: rgba(52, 64, 84, 0.60);
  bottom: 0;
  display: block;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top:0;
  z-index: 4;
}

.sidebar ul, .sidebar ol {
  list-style: none;
  margin:0;
  padding:0;
}

.sidebar li {
  padding: 0;
}

.sidebar li.active {
  background: #F2F4F7;
  border-radius: 6px;
}


/* MAIN CONTAINER */
.sidebar-main-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 400px;
  width: 85vw;
}

.sidebar-main-container .sidebar-header {
  padding: 34px 24px 30px;
  width: 85%;
}

.sidebar-header-search select {
  box-shadow: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%
}

/* select.drp-sidebar-selector option.building-disabled {
  background: #F2F4F7;
  color: #98A2B3;
}

select.drp-sidebar-selector option.building-option {
  font-weight: 600;
} */

.sidebar-main-container .sidebar-footer {
  width: 100%;
}

.sidebar-main-container .sidebar-footer .sidebar-support {
  background: #F2F4F7;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin: 0 24px;
  padding: 16px;
}

.sidebar-main-container .sidebar-footer .sidebar-support span,
.sidebar-main-container .sidebar-footer .sidebar-support a {
  color: #324E55;
}

.sidebar-main-container .sidebar-footer .sidebar-support span {
  margin-bottom: 12px;
}

.sidebar-main-container .sidebar-footer .sidebar-support .support-details {
  color: #324E55;
  font-size: 12px;
  font-weight: 500;
  margin: 0 12px 5px;
}

.sidebar-main-container .sidebar-footer .sidebar-support .support-details p {
  margin: 0 0 5px;
}

.sidebar-main-container .sidebar-footer .sidebar-support a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 6px 12px;
  text-decoration: underline;
}

.sidebar-main-container .sidebar-footer .sidebar-support a img {
  padding-right: 6px;
}

.sidebar-profile {
  align-items: center;
  border-top: #f4f5f7 2px solid;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 20px 24px 10px;
  padding: 15px 0;
}

.user-container {
  align-items: center;
  color: #324E55;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.user-container .name-email-container .sidebar-profile-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.user-container .name-email-container .sidebar-profile-email {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  max-width: 74vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-logout, 
.sidebar-settings {
  color: #324E55;
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  transition: ease-in-out all 250ms;
  &:hover {
    color: #657796;
    transition: ease-in-out all 250ms;
  }
}

@media screen and (max-width: 768px) {
  .sidebar-main-container .sidebar-header {
    width: 80%;
  }
}