/*MAIN ENERGY TAB*/
.utility-meter-detail {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.meter-row {
    display: flex;
    border-bottom: 3px solid #EAECF0;
    flex-direction: column;
    margin-bottom: 40px;
}

.meter-row:last-child {
    border: none;
}

/* COME BACK AND FIX THIS STYLING WHEN WE DO V2 */

.meter-name-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 0 0;
    padding: 8px 0 0;
    width: 100%;
}

.meter-name {
    color: #02222B;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    text-align: left;
}

.meter-button {
    background: #324E55;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    text-align: center;
    transition: ease-in all 150ms;
    
    width: 180px;

    &:hover {
        background: #02222B;
        transition: ease-in all 150ms;
    }
}

/* COME BACK AND FIX THIS STYLING WHEN WE DO V2 */

.meter-container {
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(194, 208, 209, 0.5);
    display: flex;
    justify-content: space-between;
    margin: 40px 0 60px;
    padding: 40px;
    width: calc(100% - 80px);
}

.meter-container:last-child {
    border-bottom: none;
}

/* LIVE METER DATA */
.meter-container .live-meter-data {
    max-width: 400px;
    width: 35%;
}

.meter-container .live-meter-data h4,
.utility-meter-detail .meter-data-container h4,
.utility-meter-detail .bill-analysis h4 {
    color: #02222B;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
}

.meter-container .live-meter-data hr,
.utility-meter-detail .meter-data-container hr,
.utility-meter-detail .bill-analysis hr {
    background: #EAECF0;
    border: none;
    height: 2px;
    margin: 20px 0 26px;
}

.meter-stats-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
}

.meter-stats {
    align-items: center;
    color: #324E55;
    display: flex;
    font-weight: 500;
    height: 30px;
    justify-content: space-between;
    min-width: 190px;
    padding-right: 30px;
    width: 100%;
}

.meter-stats .meter-label {
    font-weight: 600;
    padding-right: 24px;
}

.power-health {
    display: flex;
    justify-content: space-between;
}

.power-health > div {
    border-radius: 50px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 12px 2px;
}

.power-health div.online {
    background: #12B76A;
}

.power-health div.offline {
    background: #F79009;
}

.all-graphs {
    width: 100%;
}

.all-graphs > div {
    height: 300px;
}


/*UTILITY METER DETAIL*/
/* .utility-meter-detail .meter-data-container, */
.utility-meter-detail .bill-analysis {
    margin-top: 50px;
}

/* .utility-meter-detail .meter-data-container h4, */
.utility-meter-detail .bill-analysis h4 {
    font-size: 16px;
}

/* .utility-meter-detail .meter-data-container .meter-data {
    color: #324E55;
    display: grid;
    gap: 10px 40px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(30px, auto);
    width: 100%;
}

.utility-meter-detail .meter-data-container .meter-data .data-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
} */

.utility-meter-detail .bill-analysis .bill-table table {
    border: 3px #F2F4F7 solid;
    border-collapse: separate;
    border-radius: 12px;
    border-spacing: 0;
    width: 100%;
}

.utility-meter-detail .bill-analysis .bill-table table th:first-child {
    width: 18%;
}

.utility-meter-detail .bill-analysis .bill-table table th {
    width: 16%;
}

.utility-meter-detail .bill-analysis .bill-table table tr {
    height: 35px;
}

.utility-meter-detail .bill-analysis .bill-table table td:first-child,
.utility-meter-detail .bill-analysis .bill-table table thead {
    background: #F2F4F7;
    color: #324E55;
    font-size: 12px;
    font-weight: 500;
    padding: 0 20px;
}

.utility-meter-detail .bill-analysis .no-data {
    background: white !important;
    border-radius: 8px;
}

.utility-meter-detail .bill-analysis .no-data a {
    background: none;
    color: #324E55;
    padding: 0;
    text-decoration: underline;
    transition: ease-in-out all 250ms;
}

.utility-meter-detail .bill-analysis .no-data a:hover {
    background: none;
    color: #02BE7F;
    padding: 0;
    transition: ease-in-out all 250ms;
}

.utility-meter-detail .bill-analysis .bill-table table td:nth-of-type(n + 2) {
    text-align: center;
}

.utility-meter-detail .bill-analysis .bill-table table td {
    border-bottom: 2px solid #EAECF0;
    color: #324E55;
    font-size: 12px;
    font-weight: 500;
}

.utility-meter-detail .bill-analysis .bill-table table td button {
    background: #00E3AB;
    border: none;
    border-radius: 8px;
    color: #02222B;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 10px;
    transition: ease-in-out all 250ms;
}

.utility-meter-detail .bill-analysis .bill-table table td button:hover {
    background: #006149;
    color: white;
    transition: ease-in-out all 250ms;
}

.utility-meter-detail .bill-analysis .bill-table table tr:last-child td {
    border-bottom: none;
}


/* MEDIA QUERIES */
@media screen and (max-width: 1024px) {
    .meter-name {
        font-size: 22px;
    }
}