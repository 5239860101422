section.energy-section {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
}

section.energy-section a.hidden-lobby-kiosk {
  color: white;
}

section.energy-section .utility-meter-main {
  margin: 50px 52px 30px 90px;
}

section.energy-section .utility-meter-detail {
  margin: 30px 52px 30px 90px;
}

section.energy-section .utility-meter-detail.no-margin {
  margin: 0;
}

section.energy-section .utility-meter-main h1,
section.energy-section .utility-meter-detail h1 {
  color: #02222B;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 20px;
}

section.energy-section .utility-meter-main h1 {
  font-size: 20px;
}

section.energy-section .utility-meter-detail h1 {
  font-size: 26px;
}

section.energy-section .utility-meter-detail .gx-chart-container {
  background: #F2F4F7;
  height: 300px;
  padding: 12px;
}
  
section.energy-section .utility-meter-detail .gx-chart-container {
  border-radius: 8px;
  height: 100%;
  padding: 0;
}

section.energy-section .utility-meter-detail .gx-chart-container .active-chart-container {
  margin: 24px;
}

section.energy-section .utility-meter-detail .gx-chart-container .active-chart-container .performanceGraph {
  height: 600px;
}

section.energy-section .utility-meter-detail .gx-chart-container .active-chart-container .hidden {
  display: none;
  height: 0;
}

@media screen and (min-width: 1400px) {
  section.energy-section .utility-meter-main,
  section.energy-section .utility-meter-detail {
    max-width: 75vw;
  }

  section.energy-section .utility-meter-main {
    margin: 50px auto 0;
  }

  section.energy-section .utility-meter-detail {
    margin: 30px auto 60px;
  }
}