.modal-overlay {
  -webkit-backdrop-filter: blur(8px);
  background: rgba(52, 64, 84, 0.70);
  backdrop-filter: blur(8px);
  bottom: 0;
  display: flex;
  justify-content: center;
  left:0;
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top:0;
  z-index: 100;
}

.modal {
  display: flex;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  box-sizing: border-box;
  flex-flow: column;
  height: 80vh;
  justify-content: space-between;
  max-width: 350px;
  overflow-y: scroll;
  position: absolute;
  top: calc(100% - 90vh);
  width: 80vw;
}

.modal.desktop {
  height: auto;
  max-width: 800px;
  overflow: hidden;
  top: 75px;
  width: 80vw;
}

.modal .modal-content {
  max-height: 55vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 24px;
}

.modal .modal-header-container {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-200, #EAECF0);
  display: flex;
  padding: 24px;
}

.modal .modal-header-container .modal-header {
  overflow: hidden;
}

.modal .modal-header-container .modal-image {
  border: 1px solid var(--gray-200, #EAECF0);
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 0;
  margin-right: 12px;
  padding: 10px;
}

.modal .modal-header-container .modal-header .modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #1D2939;
  line-height: 28px;
}

.modal .modal-header-container .modal-header .modal-description {
  color: #324E55;
  font-size: 13px;
  line-height: 20px;
}

.modal .modal-footer {
  background: white;
  border-top: 1px solid var(--gray-200, #EAECF0);
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.modal.desktop .modal-footer {
  justify-content: flex-end;
}

.modal.desktop .modal-footer input {
  margin-left: 24px;
  transition: ease-in-out all 250ms;
  width: 120px;
}

.modal.desktop .modal-footer input:hover {
  filter: brightness(0.9);
  transition: ease-in-out all 250ms;
}

.modal .modal-footer input:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.modal .current-zone-temperature {
  font-size: 56px;
  line-height: 56px;
  font-weight: 600;
  float: right;
  letter-spacing: -1.44px;
  color: #324E55;
}

.modal.desktop .modal-homepage-zone-settings.modal-temporary-adjustment {
  max-width: 300px;
  margin: 0 auto;
}