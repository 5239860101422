.building-card {
    display: flex;
    flex-flow: wrap;
    width: 100%;
}

.building-card .building-container {
    display: flex;
    margin-right: 24px;
    width: 100%;
}

.building-row .building-name {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 0 0;
    padding: 8px 0 0;
    text-align: left;
    width: 100%;
}

.building-row .building-name h2,
.building-row .building-name .zone-button {
    color: #02222B;
    font-weight: 600;
}

.building-row .building-name h2 {
    font-size: 26px;
    margin: 0;
    width: 60vw;
}

.building-row .building-name .zone-button {
    background: #324E55;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
    transition: ease-in all 150ms;
    width: 180px;

    &:hover {
        background: #02222B;
        transition: ease-in all 150ms;
    }
}

.building-load-chart-container {
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
    padding: 16px;
    width: calc(100% - 374px);
}

@media screen and (max-width: 1024px) {
    .building-row .building-name h2 {
        font-size: 20px;
        width: 54vw;
    }
}